// @flow
import React, { type Node } from 'react';
import Link from './link';
import allImagesLinks from '../data/all-images-links';
import type { Id } from '../utils/types';

type Props = {
  id: Id,
  children: Node,
};

export default class AllImagesLink extends React.Component<Props> {
  static defaultProps = {
    children: 'View all images',
  };

  render() {
    const { children, id } = this.props;

    return (
      <p>
        <Link to={allImagesLinks[id]}>{children}</Link>
      </p>
    );
  }
}
