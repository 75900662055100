// @flow
import React from 'react';
import styled from 'styled-components';
import type { Url } from '../utils/types';

const Container = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
  margin-bottom: 30px;
`;

const IFrame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

type Props = {
  title: string,
  src: Url,
  allow: string,
  allowFullScreen: boolean,
  frameBorder: number,
  height: number,
  width: number,
};

export default class YoutubeVideo extends React.Component<Props> {
  static defaultProps = {
    allow: 'autoplay; encrypted-media',
    allowFullScreen: true,
    frameBorder: 0,
    height: 315,
    width: 560,
  };

  render() {
    return (
      <Container>
        <IFrame {...this.props} />
      </Container>
    );
  }
}
