// @flow
import type { CanyonRating } from './types';

export const parseCanyonRating = (
  canyonRatingString: string
): CanyonRating | string => {
  const str = canyonRatingString.toUpperCase();
  let technical, water, time, risk, slot;

  for (let i = 0; i < str.length; i++) {
    const letter = str.charAt(i);

    if (letter === '1' || letter === '2' || letter === '3' || letter === '4') {
      if (technical) {
        return `Technical rating defined twice: first one is ${technical} and second one is ${letter} at index ${i}`;
      }
      technical = letter;
    } else if (letter === 'A' || letter === 'B' || letter === 'C') {
      if (water) {
        return `Water rating defined twice: first one is ${water} and second one is ${letter} at index ${i}`;
      }
      water = letter;
    } else if (letter === 'I' || letter === 'V') {
      if (time) {
        return `Time rating defined twice: first one is ${time} and second one starts with ${letter} at index ${i}`;
      }
      const j = str.charAt(i + 1);
      const k = str.charAt(i + 2);
      if (letter === 'I' && j === 'I' && k === 'I') {
        time = 'III';
        i += 2;
      } else if (letter === 'I' && j === 'I') {
        time = 'II';
        i++;
      } else if (letter === 'I' && j === 'V') {
        time = 'IV';
        i++;
      } else if (letter === 'I') {
        time = 'I';
      } else if (letter === 'V' && j === 'I') {
        time = 'VI';
        i++;
      } else {
        time = 'V';
      }
    } else if (letter === 'R' || letter === 'X') {
      if (risk) {
        return `Risk rating defined twice: first one is ${risk} and second one is ${letter} at index ${i}`;
      }
      risk = letter;
    } else if (letter === 'S') {
      if (slot) {
        return `Slot designation defined twice: second one is at index ${i}`;
      }
      slot = letter;
    } else if (letter === ' ' || letter === '\t' || letter === '\n') {
    } else {
      return `Invalid letter ${letter} at index ${i}`;
    }
  }

  if (!technical) {
    return `Missing technical rating`;
  }
  if (!water) {
    return `Missing water rating`;
  }

  const canyonRating = {
    technical,
    water,
    time,
    risk,
    slot,
  };

  if (!time) {
    delete canyonRating.time;
  }
  if (!risk) {
    delete canyonRating.risk;
  }
  if (!slot) {
    delete canyonRating.slot;
  }

  return canyonRating;
};

export const canyonRating2string = (canyonRating: CanyonRating): string => {
  return (
    canyonRating.technical +
    canyonRating.water +
    (canyonRating.time || '') +
    (canyonRating.risk || '') +
    (canyonRating.slot || '')
  );
};
