// @flow
const allImagesLinks = {
  '2014-08-01-kanarra-creek':
    'https://www.amazon.com/clouddrive/share/DhiUz13ZFJIQCKf8aGQywtotMH0f1ykMZMScj3bky3m',
  '2015-10-23-egypt-2':
    'https://www.amazon.com/clouddrive/share/OCPK04JiCE9ZWXbpE3Gse2GfZfLxZtJzZea6a385xrW',
  '2016-05-14-neon-choprock':
    'https://www.amazon.com/clouddrive/share/cwerEPprI0nPjXhK7S8lCV12RDtR9QL6Dt96ydiHdP3',
  '2016-09-16-hidden':
    'https://www.amazon.com/clouddrive/share/HM3iHFubDLFq4R0PI2Uq2H0A1gPVzdEHxN0rE4eDOcS',
  '2016-09-18-bensen-creek':
    'https://www.amazon.com/clouddrive/share/EYZtS6cglWQLJV8fV37lviJ0ekV7o4iEzhIxyypP0OP',
  '2016-09-25-dry-canyon-fall-colors':
    'https://www.amazon.com/clouddrive/share/vLRAzgwf1XKg6EuUBaQ6Chpx9Ucr3wXZITNmaZASyPZ',
  '2016-10-14-aca-leaderguide-rendezvous-day-1':
    'https://www.amazon.com/clouddrive/share/YAzEdesl8HoPrOG3nYtTkCxskdXujNmTXO39SHh6DOx',
  '2016-10-15-aca-leaderguide-rendezvous-beau-beau':
    'https://www.amazon.com/clouddrive/share/FanIXiv3TAYL3ppSJeIG757KGFtAIbK19v1G1RZeVwS',
  '2016-10-15-aca-leaderguide-rendezvous-egypt-1':
    'https://www.amazon.com/clouddrive/share/QR4B2uUOvoN3G4ICglqE3NVhAVjfSjj5UNcIgFJm6ru',
  '2017-06-08-fat-mans-misery':
    'https://www.amazon.com/clouddrive/share/V8toedVEgBKRaRCaK2kvlLi6sKx2p83j7IRi4p1AQAu',
  '2017-06-09-lower-refrigerator':
    'https://www.amazon.com/clouddrive/share/Iyc7mv9EK5L84rvisOvofWZN2gp56XBCNxsUYe9MIu',
  '2017-06-10-heaps':
    'https://www.amazon.com/clouddrive/share/zei0RLXYc5ceaSGdaoAH7YvDFsrzL1LcY5duEKBqEtX',
  '2017-09-30-dry-canyon-practice':
    'https://www.amazon.com/clouddrive/share/X3A0YoD0ELjjFHINWZx03AOUyu27U6RqBEuQt8d6Yoe',
  '2017-11-11-williams-first-rappel':
    'https://www.amazon.com/clouddrive/share/lecMb2j4L28gro4uyL4NsXSNnlfejoUQr2Vzxo8jtTL',
  '2018-03-02-monkey-business':
    'https://www.amazon.com/clouddrive/share/nOZaujezL7uDPp7kboOV5r3lJf0nxsFAKKiK3JG1DXe',
  '2018-03-03-no-kidding':
    'https://www.amazon.com/clouddrive/share/Slw6qTPCQPJbRr6KklGZLiJUgGJKHNqYTjH048ay8f8',
  '2018-03-03-sandthrax':
    'https://www.amazon.com/clouddrive/share/fZh68gxo5NbahmJ0dUPx43K8hMGX8buYjzj9Mhxoqeh',
  '2018-03-24-gla-rendezvous':
    'https://www.amazon.com/clouddrive/share/hMhwtZAlJvIvk0RsuwewkZw083GIPotDfpeNtwXZVf',
  '2018-04-19-halls-creek':
    'https://www.amazon.com/clouddrive/share/hOtk0lMO3ePt9TiEeEblO1JPy9dtQaofCq3KCq005Ky',
  '2018-05-19-robbers-roost':
    'https://www.amazon.com/clouddrive/share/Q31joMXkjCGicqTrNQiv6mwHUUJMhnoAQDXjtHSQZ2Q',
  '2018-09-10-subway':
    'https://www.amazon.com/clouddrive/share/BzUPkelsko1BhFNB1H9n9V7t58V8OqY8msN3NLiKmKY',
  '2018-09-20-mystery-revisited':
    'https://www.amazon.com/clouddrive/share/lLWOCHala6RlrsHjLQfpCrQ3vn58cPBrQZWZKZfPxKb',
  '2018-09-21-engelstead':
    'https://www.amazon.com/clouddrive/share/YyeLu32GAgS1UZVLsy8aq2Cw2s4pgk64mJzKPyT3Fys',
  '2018-09-22-rock-canyon':
    'https://www.amazon.com/clouddrive/share/uVnzCpBiNeZv5QTyjiV6wQo4EBqWOW4SDYVmoVu4sc9',
  '2018-10-26-spencer':
    'https://www.amazon.com/clouddrive/share/gsx3Svdszffa2xSjYdP2iUPD7mTBupnyAzJBgtfNZXa',
  '2018-11-09-moonflower-u-turn':
    'https://www.amazon.com/clouddrive/share/XIXWfFgw9IPaFxEnqiBhr1GlnSZzTV5LPel8RnCEDVv',
  '2018-11-10-tierdrop-not-tierdrop':
    'https://www.amazon.com/clouddrive/share/wpUvYzG5xsAG1JXSo3JolZRS3JiCRBzdMnubju0Bfye',
  '2018-11-11-lomatium':
    'https://www.amazon.com/clouddrive/share/0mATuPRnr0yiyocheI2697MiABy48iLj2TtVKUNQCDF',
  '2019-03-16-north-wash':
    'https://www.amazon.com/clouddrive/share/K29PP1LcqmXy28RIy56FaqJgkJwtwKs9Dg35WYQPiVn',
  '2019-05-18-rappel-maui':
    'https://www.amazon.com/clouddrive/share/8lzLtivt0nM0GC3MPM9n4WJIovAEG50jxDFAjVOwIph',
  '2019-06-15-rocky-mouth':
    'https://www.amazon.com/clouddrive/share/dvlKLVjCYoM2qYz7bpIfjiBWDrer8LPgn8Cnxl7hHXI',
  '2020-07-12-lincolns-first-canyon':
    'https://www.amazon.com/clouddrive/share/te6BwRySFhK8fC3SU6r7em46lzUI5iB7ba0i6QMDMvX',
  '2020-07-24-crumbling-canyon':
    'https://www.amazon.com/clouddrive/share/q5R2sE1c4PoIeveptuD7gM2uAEVLMekw4yx9X99pwjL',
  '2020-09-27-great-white-icicle':
    'https://www.amazon.com/clouddrive/share/8ms1YaCkAhfgdnQ44HwYroEPKHrKjX4x7iiUbHkpJc6',
  '2021-07-05-adams-canyon':
    'https://www.amazon.com/clouddrive/share/WF3ZYPdX6zJVr1WGKaEbZxV9Xs9enQBFOBUJ5wsDaCG',
  '2021-08-06-south-fork-snoqualmie':
    'https://www.amazon.com/clouddrive/share/LcZ3n5rFtTMbEay8y2qA8DDNAGs2qABf2oLV6UrhI47',
  '2021-08-07-hall-creek':
    'https://www.amazon.com/clouddrive/share/ERFkZrosNoyZN3euIoHlV6rjD8LXhSLCL1kB9A8gY2d',
  '2021-08-08-change-creek':
    'https://www.amazon.com/clouddrive/share/3CbTJv5XoIQywRu9eiLCCG7hzOofx6CUD5Et8PZlnXW',
};

export default allImagesLinks;
