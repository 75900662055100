// @flow
import React, { type Node } from 'react';
import styled from 'styled-components';
import Collapsible from './collapsible';
import { parseCanyonRating } from '../utils/canyon-rating-helpers';
import { border, large_screen, small_screen } from '../utils/styles';
import type { CanyonRating } from '../utils/types';
import canyonRatingSystemDefinitions from '../data/canyon-rating-system-definitions';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 calc(0.833333rem - 1px) calc(0.833333rem - 1px)
    calc(0.833333rem - 1px);

  ${large_screen} {
    padding: 0 calc(1.66667rem - 1px) calc(1.66667rem - 1px)
      calc(1.66667rem - 1px);
  }
`;

const Title = styled.h2`
  margin-top: 0;
  margin-bottom: 0.83333rem;
`;

const CardRow = styled.div`
  display: flex;
  justify-content: center;

  ${small_screen} {
    line-height: 1;
  }
`;

// $FlowFixMe styled-components-v4
const Hint = styled.span.attrs({
  children: 'Tap above to change the rating',
})`
  font-size: 80%;
  font-style: italic;
  color: rgba(0, 0, 0, 0.5);
  align-self: center;
`;

const Description = styled.span`
  margin-top: 0.833333rem;
  font-size: 80%;
`;

type Props = {
  title?: string,
  rating: string,
  editable: boolean,
};

export default class CanyonRatingCard extends React.Component<
  Props,
  CanyonRating
> {
  state = {
    technical: '3',
    water: 'B',
    time: undefined,
    risk: undefined,
    slot: undefined,
  };

  static defaultProps = {
    editable: false,
  };

  componentDidMount() {
    const rating = parseCanyonRating(this.props.rating);

    if (typeof rating === 'string') {
      this.setState({
        technical: '3',
        water: 'A',
        time: undefined,
        risk: undefined,
        slot: undefined,
      });
    } else {
      this.setState({
        technical: rating.technical,
        water: rating.water,
        time: rating.time,
        risk: rating.risk,
        slot: rating.slot,
      });
    }
  }

  render() {
    const { title, editable } = this.props;

    const hint = title
      ? `View canyon rating for ${title}`
      : 'View canyon rating';
    const array = CanyonRatingCard.getRatingAsArray(this.state);
    const description = CanyonRatingCard.getDescription(this.state);

    return (
      <Collapsible visible={editable} hint={hint}>
        <Container>
          {title && <Title>{title}</Title>}
          <CardRow>
            <Card
              header="Technical"
              editable={editable}
              increment={this.incrementTechnical}
            >
              {array[0]}
            </Card>
            <Card
              header="Water"
              editable={editable}
              increment={this.incrementWater}
            >
              {array[1]}
            </Card>
            <Card
              header="Time"
              editable={editable}
              increment={this.incrementTime}
            >
              {array[2]}
            </Card>
            <Card
              header="Risk"
              editable={editable}
              increment={this.incrementRisk}
            >
              {array[3]}
            </Card>
            <Card
              header="Slot"
              editable={editable}
              increment={this.incrementSlot}
            >
              {array[4]}
            </Card>
          </CardRow>
          {editable && <Hint />}
          {description}
        </Container>
      </Collapsible>
    );
  }

  incrementTechnical = () => {
    const { technical } = this.state;
    if (technical === '1') {
      this.setState({ technical: '2' });
    } else if (technical === '2') {
      this.setState({ technical: '3' });
    } else if (technical === '3') {
      this.setState({ technical: '4' });
    } else {
      this.setState({ technical: '1' });
    }
  };

  incrementWater = () => {
    const { water } = this.state;
    if (water === 'A') {
      this.setState({ water: 'B' });
    } else if (water === 'B') {
      this.setState({ water: 'C' });
    } else {
      this.setState({ water: 'A' });
    }
  };

  incrementTime = () => {
    const { time } = this.state;
    if (!time) {
      this.setState({ time: 'I' });
    } else if (time === 'I') {
      this.setState({ time: 'II' });
    } else if (time === 'II') {
      this.setState({ time: 'III' });
    } else if (time === 'III') {
      this.setState({ time: 'IV' });
    } else if (time === 'IV') {
      this.setState({ time: 'V' });
    } else if (time === 'V') {
      this.setState({ time: 'VI' });
    } else {
      this.setState({ time: undefined });
    }
  };

  incrementRisk = () => {
    const { risk } = this.state;
    if (!risk) {
      this.setState({ risk: 'R' });
    } else if (risk === 'R') {
      this.setState({ risk: 'X' });
    } else {
      this.setState({ risk: undefined });
    }
  };

  incrementSlot = () => {
    const { slot } = this.state;
    if (!slot) {
      this.setState({ slot: 'S' });
    } else {
      this.setState({ slot: undefined });
    }
  };

  static getRatingAsArray = (rating: CanyonRating): Array<string> => {
    return [
      rating.technical,
      rating.water,
      rating.time || '-',
      rating.risk || '-',
      rating.slot || '-',
    ];
  };

  static getDescription = (rating: CanyonRating): Node => {
    const technical =
      canyonRatingSystemDefinitions.technical[rating.technical].description;
    const water = canyonRatingSystemDefinitions.water[rating.water].description;
    const time =
      rating.time &&
      canyonRatingSystemDefinitions.time[rating.time].description;
    const risk =
      rating.risk &&
      canyonRatingSystemDefinitions.risk[rating.risk].description;
    const slot =
      rating.slot &&
      canyonRatingSystemDefinitions.slot[rating.slot].description;

    return (
      <Description>
        {technical}
        <br />
        {water}
        {time && (
          <>
            <br />
            {time}
          </>
        )}
        {risk && (
          <>
            <br />
            {risk}
          </>
        )}
        {slot && (
          <>
            <br />
            {slot}
          </>
        )}
      </Description>
    );
  };
}

const CardContainer = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  background-color: transparent;
  transition: background-color 0.1s ease-out;

  &:not([disabled]) {
    cursor: pointer;
    &:hover,
    &:active {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  ${large_screen} {
    width: 110px;
    ${border};
    margin: 8px;
  }

  ${small_screen} {
    width: 50px;
    border: none;
    margin: 0;
  }
`;

const CardHeader = styled.span`
  ${small_screen} {
    display: none;
  }
`;

const CardRating = styled.span`
  color: rgba(0, 0, 0, 0.5);
  font-family: 'Montserrat', sans-serif;
  text-rendering: optimizeLegibility;
  font-size: 3rem;

  ${large_screen} {
    line-height: 59.4px;
    margin: 1rem 0;
  }
`;

class Card extends React.Component<{
  header: string,
  children: string,
  editable: boolean,
  increment: () => void,
}> {
  render() {
    const { header, children, editable, increment } = this.props;

    return (
      <CardContainer disabled={!editable} onClick={increment}>
        <CardHeader>{header}</CardHeader>
        <CardRating>{children}</CardRating>
      </CardContainer>
    );
  }
}
